<template>
  <div>
    <AppLink v-if="!Array.isArray(items)" :to="`/${value.slug}`">
      <NuxtImg
        v-if="value.thumb.src"
        data-attr="productCard_productImage"
        class="ProductCard__Thumb"
        :src="value.thumb.src"
        :alt="value.thumb.alt"
        loading="lazy"
        format="webp"
        width="270"
        height="400"
      />
    </AppLink>

    <ProductCardThumbSlider
      v-else
      v-slot="{ item }"
      :items="items"
      :selected-index="selectedThumb"
      class="flex-1"
      @option="index => emit('option', Array.isArray(items) ? items[index] : items)"
    >
      <AppLink :to="`/${item.slug}`" class="block w-full h-full">
        <NuxtImg
          v-if="item.thumb.src"
          data-attr="productCard_productImage"
          class="ProductCard__Thumb"
          :src="item.thumb.src"
          :alt="item.thumb.alt"
          width="400"
          height="400"
          loading="lazy"
        />
      </AppLink>
    </ProductCardThumbSlider>
  </div>
</template>
<script setup lang="ts">
import type { Options } from '@robustastudio/e-commerce/common';
import type { PropType } from 'vue';
import type { Unpacked } from '~~/types/utils';

const props = defineProps({
  value: {
    type: Object as PropType<Unpacked<Options>>,
    required: false,
    default: () => ({}),
  },
  items: {
    type: [Array, Object] as PropType<Options | Unpacked<Options>>,
    default: () => [],
  },
});

const emit = defineEmits(['option']);
const selectedThumb = ref();

watch(
  () => props.value,
  newValue => {
    if (Array.isArray(props.items))
      selectedThumb.value = (props.items as Options).findIndex(item => item.slug === newValue.slug);
  },
);
</script>

<style lang="postcss" scoped>
.ProductCard__Thumb {
  @apply object-cover transition-transform duration-300 ease-in-out transform;
  width: 100%;
  height: 100%;
}
</style>
